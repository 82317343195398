$mediasPath : "../sledge/medias";
@import 'settings';

.sd-back-1 {
  background-image: url("#{$mediasPath}/weather/weather.png");
}

.sd-back-2 {
  background-image: url("#{$mediasPath}/sports/sports.png");
}

.sd-back-3 {
  background-image: url("#{$mediasPath}/seigaiha/seigaiha.png");
}

.sd-back-4 {
  background-image: url("#{$mediasPath}/sakura/sakura.png");
}

.sd-back-5 {
  background-image: url("#{$mediasPath}/circles-and-roundabouts/circles-and-roundabouts.png");
}

.sd-back-7 {
  background-image: url("#{$mediasPath}/images/beach-2179183_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-8 {
  background-image: url("#{$mediasPath}/images/clouds-2329680_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-10 {
  background-image: url("#{$mediasPath}/images/coffee-2306471_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-11 {
  background-image: url("#{$mediasPath}/images/desert-2340326_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-13 {
  background-image: url("#{$mediasPath}/images/lake-2063957_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-14 {
  background-image: url("#{$mediasPath}/images/meadow-2184989_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-15 {
  background-image: url("#{$mediasPath}/images/roter-sand-2042738_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-16 {
  background-image: url("#{$mediasPath}/images/season-1985856_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-18 {
  background-image: url("#{$mediasPath}/images/sunset-2180346_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-19 {
  background-image: url("#{$mediasPath}/images/tea-lights-2223898_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-22 {
  background-image: url("#{$mediasPath}/images/tulip-2141216_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-23 {
  background-image: url("#{$mediasPath}/images/waffles-2190961_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-24 {
  background-image: url("#{$mediasPath}/images/water-1330252_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-27 {
  background-image: url("#{$mediasPath}/images/wintry-2068298_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-back-28 {
  background-image: url("#{$mediasPath}/circles/circles.png");
}

.sd-back-29 {
  background-image: url("#{$mediasPath}/arab_tile/arab_tile.png");
}

.sd-back-30 {
  background-image: url("#{$mediasPath}/brickwall/brickwall.png");
}

.sd-back-31 {
  background-image: url("#{$mediasPath}/cheap_diagonal_fabric/cheap_diagonal_fabric.png");
}

.sd-back-32 {
  background-image: url("#{$mediasPath}/circles-light/circles-light.png");
}

.sd-back-33 {
  background-image: url("#{$mediasPath}/crisp_paper_ruffles/crisp_paper_ruffles.png");
}

.sd-back-34 {
  background-image: url("#{$mediasPath}/curls/curls.png");
}

.sd-back-35 {
  background-image: url("#{$mediasPath}/dark-triangles/dark-triangles.png");
}

.sd-back-36 {
  background-image: url("#{$mediasPath}/denim/denim.png");
}

.sd-back-37 {
  background-image: url("#{$mediasPath}/doodles/doodles.png");
}

.sd-back-38 {
  background-image: url("#{$mediasPath}/dust_scratches/dust_scratches.png");
}

.sd-back-39 {
  background-image: url("#{$mediasPath}/escheresque_ste/escheresque_ste.png");
}

.sd-back-40 {
  background-image: url("#{$mediasPath}/food/food.png");
}

.sd-back-41 {
  background-image: url("#{$mediasPath}/footer_lodyas/footer_lodyas.png");
}

.sd-back-42 {
  background-image: url("#{$mediasPath}/gaming-pattern/gaming-pattern.png");
}

.sd-back-43 {
  background-image: url("#{$mediasPath}/giftly/giftly.png");
}

.sd-back-44 {
  background-image: url("#{$mediasPath}/halftone-yellow/halftone-yellow.png");
}

.sd-back-45 {
  background-image: url("#{$mediasPath}/hip-square/hip-square.png");
}

.sd-back-46 {
  background-image: url("#{$mediasPath}/intersection/intersection.png");
}

.sd-back-47 {
  background-image: url("#{$mediasPath}/large_leather/large_leather.png");
}

.sd-back-48 {
  background-image: url("#{$mediasPath}/mooning/mooning.png");
}

.sd-back-49 {
  background-image: url("#{$mediasPath}/organic-tiles/organic-tiles.png");
}

.sd-back-50 {
  background-image: url("#{$mediasPath}/paisley/paisley.png");
}

.sd-back-51 {
  background-image: url("#{$mediasPath}/project_papper/project_papper.png");
}

.sd-back-52 {
  background-image: url("#{$mediasPath}/purty_wood/purty_wood.png");
}

.sd-back-53 {
  background-image: url("#{$mediasPath}/tileable_wood_texture/tileable_wood_texture.png");
}

.sd-back-54 {
  background-image: url("#{$mediasPath}/wood_pattern/wood_pattern.png");
}

.sd-back-55 {
  background-image: url("#{$mediasPath}/tree_bark/tree_bark.png");
}

.sd-back-56 {
  background-image: url("#{$mediasPath}/upfeathers/upfeathers.png");
}

.sd-back-57 {
  background-image: url("#{$mediasPath}/restaurant/restaurant.png");
}

.sd-back-58 {
  background-image: url("#{$mediasPath}/restaurant_icons/restaurant_icons.png");
}

.sd-back-59 {
  background-image: url("#{$mediasPath}/school/school.png");
}

.sd-back-60 {
  background-image: url("#{$mediasPath}/spiration_dark/spiration_dark.png");
}

.sd-back-61 {
  background-image: url("#{$mediasPath}/spiration_light/spiration_light.png");
}

.sd-back-62 {
  background-image: url("#{$mediasPath}/subtle_stripes/subtle_stripes.png");
}

.sd-back-63 {
  background-image: url("#{$mediasPath}/vintage-concrete/vintage-concrete.png");
}

.sd-back-64 {
  background-image: url("#{$mediasPath}/xv/xv.png");
}

.sd-back-65 {
  background-image: url("#{$mediasPath}/zig_zag_wool/zig_zag_wool.png");
}

$color-scheme-1: map-get($foundation-palette, primary); /* ! primary ! */

$color-scheme-6: $black; /* black */
$color-scheme-7: $white; /* white */
$color-scheme-8: $light-gray; /*$light-gray*/
$color-scheme-9: $medium-gray; /*$medium-gray*/
$color-scheme-10: $dark-gray; /*$dark-gray*/
$color-scheme-11: map-get($foundation-palette, secondary);
$color-scheme-12: map-get($foundation-palette, success);
$color-scheme-13: map-get($foundation-palette, warning);
$color-scheme-14: map-get($foundation-palette, alert);

$color-scheme-2: #0D5199;
$color-scheme-3: #104479;
$color-scheme-4: #052445;
$color-scheme-5: #031528;

$color-scheme-15: #970F08;	/* Main Secondary color (1) */
$color-scheme-16: #EB0D02;
$color-scheme-17: #BA140B;
$color-scheme-18: #690500;
$color-scheme-19: #3E0300;

$color-scheme-20: #978308;	/* Main Secondary color (2) */
$color-scheme-21: #EBCA02;
$color-scheme-22: #BAA20B;
$color-scheme-23: #695B00;
$color-scheme-24: #3E3500;

$color-scheme-25: #975D08;	/* Main Complement color */
$color-scheme-26: #EB8D02;
$color-scheme-27: #BA740B;
$color-scheme-28: #693F00;
$color-scheme-29: #3E2500;

div.sg_text_columns {
  column-rule: 1px solid $color-scheme-2;
}

.sg-color-scheme-1-text, a.sg-color-scheme-1-text {
  color: $color-scheme-1 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-1-text::after {
  border-color: $color-scheme-1 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-1-text::after {
  border-color: transparent transparent transparent $color-scheme-1 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-1-text::after {
  border-color: transparent $color-scheme-1 transparent transparent !important; 
}

.sg-color-scheme-2-text, a.sg-color-scheme-2-text {
  color: $color-scheme-2 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-2-text::after {
  border-color: $color-scheme-2 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-2-text::after {
  border-color: transparent transparent transparent $color-scheme-2 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-2-text::after {
  border-color: transparent $color-scheme-2 transparent transparent !important; 
}

.sg-color-scheme-3-text, a.sg-color-scheme-3-text {
  color: $color-scheme-3 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-3-text::after {
  border-color: $color-scheme-3 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-3-text::after {
  border-color: transparent transparent transparent $color-scheme-3 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-3-text::after {
  border-color: transparent $color-scheme-3 transparent transparent !important; 
}

.sg-color-scheme-4-text, a.sg-color-scheme-4-text {
  color: $color-scheme-4 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-4-text::after {
  border-color: $color-scheme-4 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-4-text::after {
  border-color: transparent transparent transparent $color-scheme-4 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-4-text::after {
  border-color: transparent $color-scheme-4 transparent transparent !important; 
}

.sg-color-scheme-5-text, a.sg-color-scheme-5-text {
  color: $color-scheme-5 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-5-text::after {
  border-color: $color-scheme-5 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-5-text::after {
  border-color: transparent transparent transparent $color-scheme-5 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-5-text::after {
  border-color: transparent $color-scheme-5 transparent transparent !important; 
}

.sg-color-scheme-6-text, a.sg-color-scheme-6-text  {
  color: $color-scheme-6 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-6-text::after {
  border-color: $color-scheme-6 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-6-text::after {
  border-color: transparent transparent transparent $color-scheme-6 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-6-text::after {
  border-color: transparent $color-scheme-6 transparent transparent !important; 
}

.sg-color-scheme-7-text, a.sg-color-scheme-7-text {
  color: $color-scheme-7 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-7-text::after {
  border-color: $color-scheme-7 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-7-text::after {
  border-color: transparent transparent transparent $color-scheme-7 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-7-text::after {
  border-color: transparent $color-scheme-7 transparent transparent !important; 
}

.sg-color-scheme-8-text, a.sg-color-scheme-8-text {
  color: $color-scheme-8 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-8-text::after {
  border-color: $color-scheme-8 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-8-text::after {
  border-color: transparent transparent transparent $color-scheme-8 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-8-text::after {
  border-color: transparent $color-scheme-8 transparent transparent !important; 
}

.sg-color-scheme-9-text, a.sg-color-scheme-9-text {
  color: $color-scheme-9 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-9-text::after {
  border-color: $color-scheme-9 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-9-text::after {
  border-color: transparent transparent transparent $color-scheme-9 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-9-text::after {
  border-color: transparent $color-scheme-9 transparent transparent !important; 
}

.sg-color-scheme-10-text, a.sg-color-scheme-10-text {
  color: $color-scheme-10 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-10-text::after {
  border-color: $color-scheme-10 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-10-text::after {
  border-color: transparent transparent transparent $color-scheme-10 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-10-text::after {
  border-color: transparent $color-scheme-10 transparent transparent !important; 
}

.sg-color-scheme-11-text, a.sg-color-scheme-11-text {
  color: $color-scheme-11 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-11-text::after {
  border-color: $color-scheme-11 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-11-text::after {
  border-color: transparent transparent transparent $color-scheme-11 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-11-text::after {
  border-color: transparent $color-scheme-11 transparent transparent !important; 
}

.sg-color-scheme-12-text, a.sg-color-scheme-12-text {
  color: $color-scheme-12 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-12-text::after {
  border-color: $color-scheme-12 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-12-text::after {
  border-color: transparent transparent transparent $color-scheme-12 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-12-text::after {
  border-color: transparent $color-scheme-12 transparent transparent !important; 
}

.sg-color-scheme-13-text, a.sg-color-scheme-13-text {
  color: $color-scheme-13 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-13-text::after {
  border-color: $color-scheme-13 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-13-text::after {
  border-color: transparent transparent transparent $color-scheme-13 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-13-text::after {
  border-color: transparent $color-scheme-13 transparent transparent !important; 
}

.sg-color-scheme-14-text, a.sg-color-scheme-14-text {
  color: $color-scheme-14 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-14-text::after {
  border-color: $color-scheme-14 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-14-text::after {
  border-color: transparent transparent transparent $color-scheme-14 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-14-text::after {
  border-color: transparent $color-scheme-14 transparent transparent !important; 
}

.sg-color-scheme-15-text, a.sg-color-scheme-15-text {
  color: $color-scheme-15 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-15-text::after {
  border-color: $color-scheme-15 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-15-text::after {
  border-color: transparent transparent transparent $color-scheme-15 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-15-text::after {
  border-color: transparent $color-scheme-15 transparent transparent !important; 
}

.sg-color-scheme-16-text, a.sg-color-scheme-16-text {
  color: $color-scheme-16 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-16-text::after {
  border-color: $color-scheme-16 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-16-text::after {
  border-color: transparent transparent transparent $color-scheme-16 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-16-text::after {
  border-color: transparent $color-scheme-16 transparent transparent !important; 
}

.sg-color-scheme-17-text, a.sg-color-scheme-17-text {
  color: $color-scheme-17 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-17-text::after {
  border-color: $color-scheme-17 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-17-text::after {
  border-color: transparent transparent transparent $color-scheme-17 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-17-text::after {
  border-color: transparent $color-scheme-17 transparent transparent !important; 
}

.sg-color-scheme-18-text, a.sg-color-scheme-18-text {
  color: $color-scheme-18 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-18-text::after {
  border-color: $color-scheme-18 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-18-text::after {
  border-color: transparent transparent transparent $color-scheme-18 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-18-text::after {
  border-color: transparent $color-scheme-18 transparent transparent !important; 
}

.sg-color-scheme-19-text, a.sg-color-scheme-19-text {
  color: $color-scheme-19 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-19-text::after {
  border-color: $color-scheme-19 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-19-text::after {
  border-color: transparent transparent transparent $color-scheme-19 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-19-text::after {
  border-color: transparent $color-scheme-19 transparent transparent !important; 
}

.sg-color-scheme-20-text, a.sg-color-scheme-20-text {
  color: $color-scheme-20 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-20-text::after {
  border-color: $color-scheme-20 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-20-text::after {
  border-color: transparent transparent transparent $color-scheme-20 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-20-text::after {
  border-color: transparent $color-scheme-20 transparent transparent !important; 
}

.sg-color-scheme-21-text, a.sg-color-scheme-21-text {
  color: $color-scheme-21 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-21-text::after {
  border-color: $color-scheme-21 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-21-text::after {
  border-color: transparent transparent transparent $color-scheme-21 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-21-text::after {
  border-color: transparent $color-scheme-21 transparent transparent !important; 
}

.sg-color-scheme-22-text, a.sg-color-scheme-22-text {
  color: $color-scheme-22 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-22-text::after {
  border-color: $color-scheme-22 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-22-text::after {
  border-color: transparent transparent transparent $color-scheme-22 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-22-text::after {
  border-color: transparent $color-scheme-22 transparent transparent !important; 
}

.sg-color-scheme-23-text, a.sg-color-scheme-23-text {
  color: $color-scheme-23 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-23-text::after {
  border-color: $color-scheme-23 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-23-text::after {
  border-color: transparent transparent transparent $color-scheme-23 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-23-text::after {
  border-color: transparent $color-scheme-23 transparent transparent !important; 
}

.sg-color-scheme-24-text, a.sg-color-scheme-24-text {
  color: $color-scheme-24 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-24-text::after {
  border-color: $color-scheme-24 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-24-text::after {
  border-color: transparent transparent transparent $color-scheme-24 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-24-text::after {
  border-color: transparent $color-scheme-24 transparent transparent !important; 
}

.sg-color-scheme-25-text, a.sg-color-scheme-25-text {
  color: $color-scheme-25 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-25-text::after {
  border-color: $color-scheme-25 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-25-text::after {
  border-color: transparent transparent transparent $color-scheme-25 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-25-text::after {
  border-color: transparent $color-scheme-25 transparent transparent !important; 
}

.sg-color-scheme-26-text, a.sg-color-scheme-26-text {
  color: $color-scheme-26 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-26-text::after {
  border-color: $color-scheme-26 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-26-text::after {
  border-color: transparent transparent transparent $color-scheme-26 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-26-text::after {
  border-color: transparent $color-scheme-26 transparent transparent !important; 
}


.sg-color-scheme-27-text, a.sg-color-scheme-27-text {
  color: $color-scheme-27 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-27-text::after {
  border-color: $color-scheme-27 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-27-text::after {
  border-color: transparent transparent transparent $color-scheme-27 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-27-text::after {
  border-color: transparent $color-scheme-27 transparent transparent !important; 
}

.sg-color-scheme-28-text, a.sg-color-scheme-28-text {
  color: $color-scheme-28 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-28-text::after {
  border-color: $color-scheme-28 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-28-text::after {
  border-color: transparent transparent transparent $color-scheme-28 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-28-text::after {
  border-color: transparent $color-scheme-28 transparent transparent !important; 
}

.sg-color-scheme-29-text, a.sg-color-scheme-29-text {
  color: $color-scheme-29 !important;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a.sg-color-scheme-29-text::after {
  border-color: $color-scheme-29 transparent transparent !important;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a.sg-color-scheme-29-text::after {
  border-color: transparent transparent transparent $color-scheme-29 !important; 
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a.sg-color-scheme-29-text::after {
  border-color: transparent $color-scheme-29 transparent transparent !important; 
}


.sg-color-scheme-1, a.sg-color-scheme-1 {
  background-color: $color-scheme-1;
}

.sg-color-scheme-2, a.sg-color-scheme-2 {
  background-color: $color-scheme-2;
}

.sg-color-scheme-3, a.sg-color-scheme-3 {
  background-color: $color-scheme-3;
}

.sg-color-scheme-4, a.sg-color-scheme-4 {
  background-color: $color-scheme-4;
}

.sg-color-scheme-5, a.sg-color-scheme-5 {
  background-color: $color-scheme-5;
}

.sg-color-scheme-6, a.sg-color-scheme-6 {
  background-color: $color-scheme-6;
}

.sg-color-scheme-7, a.sg-color-scheme-7 {
  background-color: $color-scheme-7;
}

.sg-color-scheme-8, a.sg-color-scheme-8 {
  background-color: $color-scheme-8;
}

.sg-color-scheme-9, a.sg-color-scheme-9 {
  background-color: $color-scheme-9;
}

.sg-color-scheme-10, a.sg-color-scheme-10 {
  background-color: $color-scheme-10;
}

.sg-color-scheme-11, a.sg-color-scheme-11 {
  background-color: $color-scheme-11;
}

.sg-color-scheme-12, a.sg-color-scheme-12 {
  background-color: $color-scheme-12;
}

.sg-color-scheme-13, a.sg-color-scheme-13 {
  background-color: $color-scheme-13;
}

.sg-color-scheme-14, a.sg-color-scheme-14 {
  background-color: $color-scheme-14;
}

.sg-color-scheme-15, a.sg-color-scheme-15 {
  background-color: $color-scheme-15;
}

.sg-color-scheme-16, a.sg-color-scheme-16 {
  background-color: $color-scheme-16;
}

.sg-color-scheme-17, a.sg-color-scheme-17 {
  background-color: $color-scheme-17;
}

.sg-color-scheme-18, a.sg-color-scheme-18 {
  background-color: $color-scheme-18;
}

.sg-color-scheme-19, a.sg-color-scheme-19 {
  background-color: $color-scheme-19;
}

.sg-color-scheme-20, a.sg-color-scheme-20 {
  background-color: $color-scheme-20;
}

.sg-color-scheme-21, a.sg-color-scheme-21 {
  background-color: $color-scheme-21;
}

.sg-color-scheme-22, a.sg-color-scheme-22 {
  background-color: $color-scheme-22;
}

.sg-color-scheme-23, a.sg-color-scheme-23 {
  background-color: $color-scheme-23;
}

.sg-color-scheme-24, a.sg-color-scheme-24 {
  background-color: $color-scheme-24;
}

.sg-color-scheme-25, a.sg-color-scheme-25 {
  background-color: $color-scheme-25;
}

.sg-color-scheme-26, a.sg-color-scheme-26 {
  background-color: $color-scheme-26;
}

.sg-color-scheme-27, a.sg-color-scheme-27 {
  background-color: $color-scheme-27;
}

.sg-color-scheme-28, a.sg-color-scheme-28 {
  background-color: $color-scheme-28;
}

.sg-color-scheme-29, a.sg-color-scheme-29 {
  background-color: $color-scheme-29;
}

/* svg stroke class*/
.sg-color-scheme-1-stroke {
  stroke: $color-scheme-1 !important;
}

.sg-color-scheme-2-stroke {
  stroke: $color-scheme-2 !important;
}

.sg-color-scheme-3-stroke {
  stroke: $color-scheme-3 !important;
}

.sg-color-scheme-4-stroke {
  stroke: $color-scheme-4 !important;
}

.sg-color-scheme-5-stroke {
  stroke: $color-scheme-5 !important;
}

.sg-color-scheme-6-stroke  {
  stroke: $color-scheme-6 !important;
}

.sg-color-scheme-7-stroke {
  stroke: $color-scheme-7 !important;
}

.sg-color-scheme-8-stroke {
  stroke: $color-scheme-8 !important;
}

.sg-color-scheme-9-stroke {
  stroke: $color-scheme-9 !important;
}

.sg-color-scheme-10-stroke {
  stroke: $color-scheme-10 !important;
}

.sg-color-scheme-11-stroke {
  stroke: $color-scheme-11 !important;
}

.sg-color-scheme-12-stroke {
  stroke: $color-scheme-12 !important;
}

.sg-color-scheme-13-stroke {
  stroke: $color-scheme-13 !important;
}

.sg-color-scheme-14-stroke {
  stroke: $color-scheme-14 !important;
}

.sg-color-scheme-15-stroke {
  stroke: $color-scheme-15 !important;
}

.sg-color-scheme-16-stroke {
  stroke: $color-scheme-16 !important;
}

.sg-color-scheme-17-stroke {
  stroke: $color-scheme-17 !important;
}

.sg-color-scheme-18-stroke {
  stroke: $color-scheme-18 !important;
}

.sg-color-scheme-19-stroke {
  stroke: $color-scheme-19 !important;
}

.sg-color-scheme-20-stroke {
  stroke: $color-scheme-20 !important;
}

.sg-color-scheme-21-stroke {
  stroke: $color-scheme-21 !important;
}

.sg-color-scheme-22-stroke {
  stroke: $color-scheme-22 !important;
}

.sg-color-scheme-23-stroke {
  stroke: $color-scheme-23 !important;
}

.sg-color-scheme-24-stroke {
  stroke: $color-scheme-24 !important;
}

.sg-color-scheme-25-stroke {
  stroke: $color-scheme-25 !important;
}

.sg-color-scheme-26-stroke {
  stroke: $color-scheme-26 !important;
}

.sg-color-scheme-27-stroke {
  stroke: $color-scheme-27 !important;
}

.sg-color-scheme-28-stroke {
  stroke: $color-scheme-28 !important;
}

.sg-color-scheme-29-stroke {
  stroke: $color-scheme-29 !important;
}

/*sitemap*/
.sg-color-scheme-1-link li a {
  color: $color-scheme-1;
}

.sg-color-scheme-1-title li.menu-text { 
  color: $color-scheme-1;
}

.sg-color-scheme-2-link li a {
  color: $color-scheme-2;
}

.sg-color-scheme-2-title li.menu-text { 
  color: $color-scheme-2;
}

.sg-color-scheme-3-link li a {
  color: $color-scheme-3;
}

.sg-color-scheme-3-title li.menu-text { 
  color: $color-scheme-3;
}

.sg-color-scheme-4-link li a {
  color: $color-scheme-4;
}

.sg-color-scheme-4-title li.menu-text { 
  color: $color-scheme-4;
}

.sg-color-scheme-5-link li a {
  color: $color-scheme-5;
}

.sg-color-scheme-5-title li.menu-text { 
  color: $color-scheme-5;
}

.sg-color-scheme-6-link li a {
  color: $color-scheme-6;
}

.sg-color-scheme-6-title li.menu-text { 
  color: $color-scheme-6;
}

.sg-color-scheme-7-link li a {
  color: $color-scheme-7;
}

.sg-color-scheme-7-title li.menu-text { 
  color: $color-scheme-7;
}

.sg-color-scheme-8-link li a {
  color: $color-scheme-8;
}

.sg-color-scheme-8-title li.menu-text { 
  color: $color-scheme-8;
}

.sg-color-scheme-9-link li a {
  color: $color-scheme-9;
}

.sg-color-scheme-9-title li.menu-text { 
  color: $color-scheme-9;
}

.sg-color-scheme-10-link li a {
  color: $color-scheme-10;
}

.sg-color-scheme-10-title li.menu-text { 
  color: $color-scheme-10;
}

.sg-color-scheme-11-link li a {
  color: $color-scheme-11;
}

.sg-color-scheme-11-title li.menu-text { 
  color: $color-scheme-11;
}

.sg-color-scheme-12-link li a {
  color: $color-scheme-12;
}

.sg-color-scheme-12-title li.menu-text { 
  color: $color-scheme-12;
}

.sg-color-scheme-13-link li a {
  color: $color-scheme-13;
}

.sg-color-scheme-13-title li.menu-text { 
  color: $color-scheme-13;
}

.sg-color-scheme-14-link li a {
  color: $color-scheme-14;
}

.sg-color-scheme-14-title li.menu-text { 
  color: $color-scheme-14;
}

.sg-color-scheme-15-link li a {
  color: $color-scheme-15;
}

.sg-color-scheme-15-title li.menu-text { 
  color: $color-scheme-15;
}

.sg-color-scheme-16-link li a {
  color: $color-scheme-16;
}

.sg-color-scheme-16-title li.menu-text { 
  color: $color-scheme-16;
}

.sg-color-scheme-17-link li a {
  color: $color-scheme-17;
}

.sg-color-scheme-17-title li.menu-text { 
  color: $color-scheme-17;
}

.sg-color-scheme-18-link li a {
  color: $color-scheme-18;
}

.sg-color-scheme-18-title li.menu-text { 
  color: $color-scheme-18;
}

.sg-color-scheme-19-link li a {
  color: $color-scheme-19;
}

.sg-color-scheme-19-title li.menu-text { 
  color: $color-scheme-19;
}

.sg-color-scheme-20-link li a {
  color: $color-scheme-20;
}

.sg-color-scheme-20-title li.menu-text { 
  color: $color-scheme-20;
}

.sg-color-scheme-21-link li a {
  color: $color-scheme-21;
}

.sg-color-scheme-21-title li.menu-text { 
  color: $color-scheme-21;
}

.sg-color-scheme-22-link li a {
  color: $color-scheme-22;
}

.sg-color-scheme-22-title li.menu-text { 
  color: $color-scheme-22;
}

.sg-color-scheme-23-link li a {
  color: $color-scheme-23;
}

.sg-color-scheme-23-title li.menu-text { 
  color: $color-scheme-23;
}

.sg-color-scheme-24-link li a {
  color: $color-scheme-24;
}

.sg-color-scheme-24-title li.menu-text { 
  color: $color-scheme-24;
}

.sg-color-scheme-25-link li a {
  color: $color-scheme-25;
}

.sg-color-scheme-25-title li.menu-text { 
  color: $color-scheme-25;
}

.sg-color-scheme-26-link li a {
  color: $color-scheme-26;
}

.sg-color-scheme-26-title li.menu-text { 
  color: $color-scheme-26;
}

.sg-color-scheme-27-link li a {
  color: $color-scheme-27;
}

.sg-color-scheme-27-title li.menu-text { 
  color: $color-scheme-27;
}

.sg-color-scheme-28-link li a {
  color: $color-scheme-28;
}

.sg-color-scheme-28-title li.menu-text { 
  color: $color-scheme-28;
}

.is-drilldown .drilldown a {
  color: map-get($foundation-palette, primary) !important;
}

.sg-txt-align-right {
  text-align: right;  
}

.sg-txt-align-center {
  text-align: center;  
}

.sg-txt-align-justify {
  text-align: justify;  
}

.sg-padding-medium {
  padding-top: 20px !important;
  padding-bottom: 20px !important; 
}

.sg-padding-large {
  padding-top: 40px !important;
  padding-bottom: 40px !important; 
}

div.sg_text_cont p {
  margin-bottom: 0.1rem;
}