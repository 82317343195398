/* button */
button.sd_round, a.sd_round {
  border-radius: 50px;
}

button.radius, a.radius {
  border-radius: 10px;
}

button.sd_uppercase, a.sd_uppercase {
  text-transform: uppercase;
}

/* background */
.sd-bg-pattern {
  background-repeat: repeat;
}

.sd-bg-media {
  background-repeat: no-repeat;
  background-size: cover;
}

.sd-bg-media.sd-bg-repeat {
  background-repeat: repeat;
  background-size: auto;
}

.sd-fullscreen-bg-video {
    position: absolute;
    left:0;
    top: 0;
    width: 100%; 
    height: auto;
    overflow: hidden;
}

.sg-bg-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

/* logo */
.sg-logo {
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.sg-logo.sg-large {
  height: 200px;
  width: 200px;
}

.sg-logo.sg-small {
  height: 50px;
  width: 50px;
}

/* top bar */
.top-bar {
  display: flex;
  align-items: center;
}

.sg-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sg-menu-sticky .sg-menu {
  position: fixed;
}

.top-bar ul:not(.submenu) {
  background-color: rgba(255, 255, 255, 0) !important;
}

.top-bar.sg-menu-back-light, .top-bar.sg-menu-back-light ul ul {
  background-color: rgba(255, 255, 255, 0.5);
}

.top-bar.sg-menu-back-dark, .top-bar.sg-menu-back-dark ul ul {
  background-color: rgba(0, 0, 0, 0.5);
}

.top-bar.sg-menu-back-light li {
  filter: brightness(45%);
}

.top-bar.sg-menu-back-dark li {
  filter: brightness(140%);
}

.top-bar.sg-menu-back-dark strong {
  color: #ddd;
}

.top-bar-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar-title .sg-logo {
  height: 30px;
  width: 50px;
  float: left;
  margin: 0 0 0 10px !important;
}

.top-bar-title strong {
  margin-left: 10px;
}

.sg-logo-menu.sg-logo.sg-large {
  height: 50px;
  width: 100px;
}

.sg-logo-menu.sg-logo.sg-small {
  height: 20px;
  width: 30px;
}

@media screen and (max-width: 39.9375em) {

  .top-bar {
    display: block;
    align-items: center;
  }

  .top-bar-title .sg-logo {
    position: absolute;
    right: 0;
    height: 30px;
  }

  .sg-logo.sg-logo-menu.sg-large {
    height: 35px;
    width: 90px;
    top: 10px;
    right: 10px;
  }

  .sg-logo.sg-logo-menu.sg-small {
    height: 20px;
    width: 30px;
  }
}

@media screen and (min-width: 40em) {
  
  .sg-menu-shrink .top-bar {
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  .sg-menu-shrink:not(.sg-menu-sticky-small) .top-bar {
    height: 100px;
  }

  .sg-menu-shrink .sg-central-block-content {
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  .sg-menu-shrink:not(.sg-menu-sticky-small) .sg-central-block-content {
    margin-top: 80px;
  }

  .sg-menu-shrink.sg-menu-sticky-small .top-bar {
    height: 55px;
  }

  .sg-menu-shrink .top-bar-title,
  .sg-menu-shrink .sg-top-menu {  
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    margin-top: 0px;
  }

  .sg-menu-shrink:not(.sg-menu-sticky-small) .top-bar-title,
  .sg-menu-shrink:not(.sg-menu-sticky-small) .sg-top-menu {  
    margin-top: 50px !important;
  }
}

